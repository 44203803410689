import axios from '../axiosConfig';

const ClienteService = {
  getClients: async () => {
    const response = await axios.get('/clients');
    return response.data;
  },
  getClientById: async (id) => {
    const response = await axios.get(`/clients/${id}`);
    return response.data;
  },
  createClient: async (client) => {
    const response = await axios.post('/clients', client);
    return response.data;
  },
  updateClient: async (id, client) => {
    const response = await axios.put(`/clients/${id}`, client);
    return response.data;
  },
  deleteClient: async (id) => {
    const response = await axios.delete(`/clients/${id}`);
    return response.data;
  },
};

export default ClienteService;
