import React, { useState, useEffect } from "react";
import CategoryService from "../services/CategoryService";
import ClientService from "../services/ClienteService";

const CategoryManager = () => {
  const [categories, setCategories] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [newBalance, setNewBalance] = useState(0);

  useEffect(() => {
    fetchCategories();
    fetchClients();
  }, []);

  const fetchCategories = async () => {
    const data = await CategoryService.getAllCategories();
    setCategories(data);
  };

  const fetchClients = async () => {
    const data = await ClientService.getClients();
    setClients(data);
  };

  const handleAddCategory = async () => {
    if (newCategory.trim() && selectedClient) {
      await CategoryService.createCategory({
        name: newCategory,
        balance: parseFloat(newBalance),
        id_cliente: selectedClient,
      });
      setNewCategory("");
      setNewBalance(0);
      setSelectedClient("");
      fetchCategories();
    }
  };

  const handleDeleteCategory = async (id) => {
    await CategoryService.deleteCategory(id);
    fetchCategories();
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Categorías</h2>

      <div className="mb-4">
        <select
          value={selectedClient}
          onChange={(e) => setSelectedClient(e.target.value)}
          className="p-2 border rounded-md mr-2"
        >
          <option value="">Seleccionar cliente</option>
          {clients.map((client) => (
            <option key={client.id} value={client.id}>
              {client.name} {}
            </option>
          ))}
        </select>
        <input
          type="text"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder="Nueva categoría"
          className="p-2 border rounded-md mr-2"
        />
        <input
          type="number"
          value={newBalance}
          onChange={(e) => setNewBalance(e.target.value)}
          placeholder="Balance inicial"
          className="p-2 border rounded-md mr-2"
        />
        <button
          onClick={handleAddCategory}
          className="p-2 bg-blue-500 text-white rounded-md"
        >
          Agregar
        </button>
      </div>

      <ul className="mt-4">
        {categories.map((category) => (
          <li key={category.id} className="flex justify-between mb-2">
            <div>
              <span className="font-semibold">{category.name}</span>
              <span className="ml-4 text-gray-500">
                Cliente: {category.client.name || "Sin asignar"}{" "}
                {}
              </span>
              <span className="ml-4 text-gray-500">
                Balance: ${parseFloat(category.balance || 0).toFixed(2)}
              </span>
            </div>
            <button
              onClick={() => handleDeleteCategory(category.id)}
              className="text-red-500 hover:text-red-700"
            >
              Eliminar
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryManager;
