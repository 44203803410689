import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="bg-gray-200 p-4 rounded-lg shadow-md mb-6">
      <ul className="flex justify-around">
        <li>
        <a
            href="#clientes"
            className="text-blue-500 hover:text-blue-700 font-semibold"
          >
            Clientes
          </a>
        </li>
        <li>
          <a
            href="#pagos"
            className="text-blue-500 hover:text-blue-700 font-semibold"
          >
            Pagos
          </a>
        </li>
        <li>
          <a
            href="#estadisticas"
            className="text-blue-500 hover:text-blue-700 font-semibold"
          >
            Estadísticas
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
