import React, { useState, useEffect } from 'react';
import PaymentService from '../services/PaymentService';
import CategoryService from '../services/CategoryService';
import ClienteService from '../services/ClienteService';

const PaymentsList = () => {
  const [payments, setPayments] = useState([]);
  const [clients, setClients] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPayment, setNewPayment] = useState({
    client_id: '',
    amount: '',
    description: '',
    payment_date: '',
    category_id: ''
  });

  // Fetch all necessary data
  const fetchInitialData = async () => {
    try {
      const [paymentsData, categoriesData, clientsData] = await Promise.all([
        PaymentService.getPayments(),
        CategoryService.getAllCategories(),
        ClienteService.getClients()
      ]);

      setPayments(paymentsData);
      setCategories(categoriesData);
      setClients(clientsData);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch data');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  // Handle client selection and filter categories
  const handleClientChange = (e) => {
    const clientId = e.target.value;
    
    // Update payment state with selected client
    setNewPayment(prev => ({
      ...prev,
      client_id: clientId,
      category_id: '' // Reset category when client changes
    }));

    // Filter categories for the selected client
    const clientCategories = categories.filter(
      category => category.id_cliente === parseInt(clientId)
    );

    setFilteredCategories(clientCategories);
  };

  // Handle input changes for new payment
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPayment(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Create new payment
  const handleCreatePayment = async (e) => {
    e.preventDefault();
    try {
      await PaymentService.createPayment(newPayment);
      setIsModalOpen(false);
      // Reset form
      setNewPayment({
        client_id: '',
        amount: '',
        description: '',
        payment_date: '',
        category_id: ''
      });
      // Refresh payments list
      fetchInitialData();
      // Reset filtered categories
      setFilteredCategories([]);
    } catch (err) {
      setError('Failed to create payment');
    }
  };

  // Delete payment
  const handleDeletePayment = async (id) => {
    if (window.confirm('Are you sure you want to delete this payment?')) {
      try {
        await PaymentService.deletePayment(id);
        // Refresh payments list
        fetchInitialData();
      } catch (err) {
        setError('Failed to delete payment');
      }
    }
  };

  // Helper function to safely format amount
  const formatAmount = (amount) => {
    const numAmount = typeof amount === 'string' ? parseFloat(amount) : amount;
    return !isNaN(numAmount) ? `$${numAmount.toFixed(2)}` : 'N/A';
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        {error}
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <div className="py-8">
        {/* Add Payment Button */}
        <div className="mb-4">
          <button 
            onClick={() => setIsModalOpen(true)}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Agregar Pago
          </button>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
              <form onSubmit={handleCreatePayment} className="space-y-4">
                <h2 className="text-lg font-bold mb-4">Add New Payment</h2>
                
                <select
                  name="client_id"
                  value={newPayment.client_id}
                  onChange={handleClientChange}
                  className="w-full px-3 py-2 border rounded"
                  required
                >
                  <option value="">Select Client</option>
                  {clients.map((client) => (
                    <option key={client.id} value={client.id}>
                      {client.name || `Client ${client.id}`}
                    </option>
                  ))}
                </select>
                
                <input
                  type="number"
                  name="amount"
                  value={newPayment.amount}
                  onChange={handleInputChange}
                  placeholder="Amount"
                  className="w-full px-3 py-2 border rounded"
                  step="0.01"
                  required
                />
                
                <input
                  type="text"
                  name="description"
                  value={newPayment.description}
                  onChange={handleInputChange}
                  placeholder="Description"
                  className="w-full px-3 py-2 border rounded"
                />
                
                <input
                  type="date"
                  name="payment_date"
                  value={newPayment.payment_date}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                  required
                />
                
                {/* Category Dropdown (Dynamic based on selected client) */}
                <select
                  name="category_id"
                  value={newPayment.category_id}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded"
                  disabled={!newPayment.client_id}
                  required
                >
                  <option value="">
                    {newPayment.client_id 
                      ? 'Select Category' 
                      : 'Please select a client first'}
                  </option>
                  {filteredCategories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name} (Balance: ${category.balance || 0})
                    </option>
                  ))}
                </select>
                
                <div className="flex justify-between">
                  <button 
                    type="submit"
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Create Payment
                  </button>
                  <button 
                    type="button"
                    onClick={() => {
                      setIsModalOpen(false);
                      setFilteredCategories([]);
                    }}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow" style={{ maxHeight: "300px" }}>
            <table className="min-w-full leading-normal" >
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    ID
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Client
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Amount
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Description
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Payment Date
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Category
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {payments.map((payment) => {
                  // Find corresponding client and category names
                  const client = clients.find(c => c.id === payment.client_id);
                  const category = categories.find(c => c.id === payment.category_id);

                  return (
                    <tr key={payment.id}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {payment.id}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {client ? client.name : `Client ${payment.client_id}`}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {formatAmount(payment.amount)}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {payment.description}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {payment.payment_date ? new Date(payment.payment_date).toLocaleDateString() : 'N/A'}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {category ? category.name : `Category ${payment.category_id}`}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        <button 
                          onClick={() => handleDeletePayment(payment.id)}
                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded text-xs"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
              
            {payments.length === 0 && (
              <div className="text-center py-4 text-gray-500">
                No payments found
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsList;