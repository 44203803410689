import React, { useEffect, useState } from "react";
import ClienteService from "../services/ClienteService";
import CategoryService from "../services/CategoryService";
import PaymentService from "../services/PaymentService";

const PaymentStatistics = () => {
  const [payments, setPayments] = useState([]);
  const [clients, setClients] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [statistics, setStatistics] = useState({
    totalPayments: 0,
    totalAmount: 0,
    pendingBalance: 0,
    balance: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const clientData = await ClienteService.getClients();
        setClients(clientData);

        const categoryData = await CategoryService.getAllCategories();
        setCategories(categoryData);

        const paymentData = await PaymentService.getPayments();
        setPayments(paymentData);
        setFilteredPayments(paymentData);

        calculateStatistics(paymentData);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
      }
    };

    fetchData();
  }, []);

  const calculateStatistics = (data) => {
    const totalAmount = data.reduce((sum, payment) => sum + (Number(payment.amount) || 0), 0);
  
    let balance = 0;
    let pendingBalance = 0;
  
    if (selectedCategory) {
      const categoria = categories.find(
        (category) => category.id === parseInt(selectedCategory)
      );
  
      if (categoria) {
        balance = parseFloat(categoria.balance || 0); 
      }
       pendingBalance = balance - totalAmount;
    }
  
    
  
    setStatistics({
      totalPayments: data.length,
      totalAmount,
      pendingBalance,
      balance,
    });
  };
  
  
  useEffect(() => {
    filterPayments(selectedClient, selectedCategory); 
  }, [selectedCategory, payments]);
  

  const handleClientChange = (clientId) => {
    setSelectedClient(clientId);
    filterPayments(clientId, selectedCategory);
  };

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    filterPayments(selectedClient, categoryId);
  };

  const filterPayments = (clientId, categoryId) => {
    let filtered = payments;

    if (clientId) {
      filtered = filtered.filter(
        (payment) => payment.client_id === parseInt(clientId)
      );
    }

    if (categoryId) {      
      filtered = filtered.filter(
        (payment) => payment.category_id === parseInt(categoryId)
      );
    }

    setFilteredPayments(filtered);
    calculateStatistics(filtered);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mt-6">
      <h2 className="text-2xl font-bold text-gray-800 mb-4">
        Estadísticas de Pagos
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <div>
          <label
            htmlFor="clientSelect"
            className="block text-gray-700 font-medium mb-2"
          >
            Seleccionar Cliente:
          </label>
          <select
      id="clientSelect"
      value={selectedClient || ""}
      onChange={(e) => handleClientChange(e.target.value)}
      className="border p-2 rounded-md w-full"
    >
      <option value="">Seleccionar Cliente</option>
      {clients.map((client) => (
        <option key={client.id} value={client.id}>
          {client.name}
        </option>
      ))}
    </select>
  </div>

  <div className="mt-4">
    <label
      htmlFor="categorySelect"
      className="block text-gray-700 font-medium mb-2"
    >
      Seleccionar Categoría:
    </label>
    <select
      id="categorySelect"
      value={selectedCategory || ""}
      onChange={(e) => handleCategoryChange(e.target.value)}
      className="border p-2 rounded-md w-full"
      disabled={!selectedClient} 
    >
      <option value="">Todas las Categorías</option>
      {categories
        .filter((category) => category.id_cliente === parseInt(selectedClient)) 
        .map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
    </select>
        </div>
      </div>

      {/* Resumen de Estadísticas */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-gray-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-600">Total de Pagos</h3>
          <p className="text-2xl font-bold text-blue-600">
            {statistics.totalPayments}
          </p>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-600">Monto Total</h3>
          <p className="text-2xl font-bold text-green-600">
            ${statistics.totalAmount.toFixed(2)}
          </p>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-600">Saldo Pendiente</h3>
          <p className="text-2xl font-bold text-red-600">
            ${statistics.pendingBalance.toFixed(2)}
          </p>
        </div>
        <div className="bg-gray-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold text-gray-600">Balance</h3>
          <p className="text-2xl font-bold text-orange-600">
            ${statistics.balance.toFixed(2)}
          </p>
        </div>
      </div>

      {/* Tabla de Pagos */}
      <h3 className="text-xl font-semibold text-gray-700 mb-3">Pagos</h3>
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-200 text-left text-sm">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2">Cliente</th>
              <th className="border border-gray-300 px-4 py-2">Categoría</th>
              <th className="border border-gray-300 px-4 py-2">Monto</th>
              <th className="border border-gray-300 px-4 py-2">Saldo Pendiente</th>
              <th className="border border-gray-300 px-4 py-2">Fecha</th>
            </tr>
          </thead>
          <tbody>
            {filteredPayments.map((payment) => (
              <tr key={payment.id}>
                <td className="border border-gray-300 px-4 py-2">
                  {clients.find((client) => client.id === payment.client_id)
                    ?.name || "Cliente Desconocido"}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {categories.find(
                    (category) => category.id === payment.category_id
                  )?.name || "Sin categoría"}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  ${Number(payment.amount || 0).toFixed(2)}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  ${Number(payment.pending_amount || 0).toFixed(2)}
                </td>
                <td className="border border-gray-300 px-4 py-2">
                  {payment.payment_date}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentStatistics;
