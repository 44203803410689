import axios from '../axiosConfig';

const PaymentService = {
  getPayments: async () => {
    const response = await axios.get('/payments');
    return response.data;
  },
  getPaymentById: async (id) => {
    const response = await axios.get(`/payments/${id}`);
    return response.data;
  },
  createPayment: async (payment) => {
    const response = await axios.post('/payments', payment);
    return response.data;
  },
  updatePayment: async (id, payment) => {
    const response = await axios.put(`/payments/${id}`, payment);
    return response.data;
  },
  deletePayment: async (id) => {
    const response = await axios.delete(`/payments/${id}`);
    return response.data;
  },
};

export default PaymentService;
