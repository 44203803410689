import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ClientList from "../components/ClientList";
import ClientForm from "../components/ClientForm";
import PaymentStatistics from "../components/PaymentStatistics";
import Navbar from "../components/Navbar";
import CategoryManager from "../components/CategoryManager";
import CategoryService from "../services/CategoryService";
import PaymentRegister from "../components/PaymentRegister";
import api from "../axiosConfig"; // Axios configurado con intercepción de token

const DashboardPage = () => {
  const [clients, setClients] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [filter, setFilter] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(true); // Indicador de carga
  const navigate = useNavigate();

  const apiUrl = "https://apipayments.criptweb.com/api";

  // Validar token y usuario antes de cargar datos
  useEffect(() => {
    const validateUser = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/"); // Redirige al login si no hay token
        return;
      }

      try {
        const response = await api.get("/user", {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (!response.data || !response.data.user) {
          throw new Error("Usuario no autenticado");
        }
      } catch (error) {
        console.error("Error al validar usuario:", error);
        localStorage.removeItem("token"); // Elimina token si es inválido
        navigate("/"); // Redirige al login
      } finally {
        setLoading(false); // Finaliza la carga
      }
    };

    validateUser();
  }, [navigate]);

  const fetchClients = async () => {
    try {
      const response = await api.get("/clients");
      setClients(response.data);
    } catch (error) {
      console.error("Error al cargar los clientes:", error);
    }
  };

  const fetchPayments = async () => {
    try {
      const response = await api.get("/payments");
      setPayments(response.data);
    } catch (error) {
      console.error("Error al cargar los pagos:", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const data = await CategoryService.getAllCategories();
      setCategories(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error("Error al cargar categorías:", error);
      setCategories([]);
    }
  };

  useEffect(() => {
    if (!loading) {
      fetchClients();
      fetchPayments();
      fetchCategories();
    }
  }, [loading]);

  const handleSaveClient = async (client) => {
    try {
      if (client.id) {
        await api.put(`/clients/${client.id}`, client);
      } else {
        await api.post("/clients", client);
      }
      fetchClients();
    } catch (error) {
      console.error("Error al guardar el cliente:", error);
    }
  };

  const handleDeleteClient = async (id) => {
    try {
      await api.delete(`/clients/${id}`);
      fetchClients();
    } catch (error) {
      console.error("Error al eliminar el cliente:", error);
    }
  };

  const handleSavePayment = async (payment) => {
    try {
      if (payment.id) {
        await api.put(`/payments/${payment.id}`, payment);
      } else {
        await api.post("/payments", payment);
      }
      fetchPayments();
    } catch (error) {
      console.error("Error al guardar el pago:", error);
    }
  };

  const handleDeletePayment = async (id) => {
    try {
      await api.delete(`/payments/${id}`);
      fetchPayments();
    } catch (error) {
      console.error("Error al eliminar el pago:", error);
    }
  };

  const filteredPayments = payments.filter(
    (payment) =>
      payment.description.toLowerCase().includes(filter.toLowerCase()) ||
      clients.find(
        (client) =>
          client.id === payment.client_id &&
          client.name.toLowerCase().includes(filter.toLowerCase())
      )
  );

  if (loading) {
    return <div>Cargando...</div>; // Muestra un indicador de carga mientras se valida el usuario
  }

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="container mx-auto bg-white shadow-lg rounded-lg p-6">
        <Navbar />
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
          Gestor de Pagos
        </h1>
        <CategoryManager />
        <div className="flex flex-col md:flex-row md:space-x-6 mt-5">
          <div className="bg-gray-100 p-4 rounded-lg shadow-md flex-1">
            <h2
              className="text-2xl font-semibold text-gray-700 mb-4"
              id="clientes"
            >
              Clientes
            </h2>
            <ClientList
              clients={clients}
              onEdit={setSelectedClient}
              onDelete={handleDeleteClient}
            />
            <ClientForm client={selectedClient} onSave={handleSaveClient} />
          </div>
        </div>

        <PaymentRegister />
        <div className="flex-1" id="estadisticas">
          <PaymentStatistics apiUrl={apiUrl} />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
