import axios from '../axiosConfig';

const CategoryService = {
    getAllCategories: async () => {
      const response = await axios.get('/categories'); 
      return response.data;
    },
  
    createCategory: async (category) => {
      const response = await axios.post('/categories', category);
      return response.data;
    },
  
    deleteCategory: async (id) => {
      const response = await axios.delete(`/categories/${id}`);
      return response.data;
    },
  };
  
  

export default CategoryService;
