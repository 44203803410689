import React from 'react';

const ClientList = ({ clients, onEdit, onDelete }) => {
  return (
    <ul className="space-y-3">
      {clients.map((client) => (
        <li
          key={client.id}
          className="p-3 bg-white shadow rounded-lg flex justify-between items-center"
        >
          <span>{client.name}</span>
          <div>
            <button
              className="text-blue-500 font-semibold hover:underline mr-2"
              onClick={() => onEdit(client)}
            >
              Editar
            </button>
            <button
              className="text-red-500 font-semibold hover:underline"
              onClick={() => onDelete(client.id)}
            >
              Eliminar
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ClientList;
