import React, { useState, useEffect } from "react";

const ClientForm = ({ client, onSave }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (client) {
      setName(client.name);
      setEmail(client.email);
      setPhone(client.phone);
    }
  }, [client]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({ id: client?.id, name, email, phone });
    setName("");
    setEmail("");
    setPhone("");
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Nombre del cliente"
          className="border p-2 rounded-md w-full"
        />
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Correo del cliente"
          className="border p-2 rounded-md w-full"
        />
        <input
          type="tel"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          placeholder="Telefono del cliente"
          className="border p-2 rounded-md w-full"
        />

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
        >
          {client ? "Actualizar" : "Agregar"}
        </button>
      </div>
    </form>
  );
};

export default ClientForm;
